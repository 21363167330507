<template>
  <div class="recharge-review">
    <en-table-layout :tableData="tableData.data" :loading="loading" @selection-change="handleSelectionChange">
      <template slot="toolbar">
        <el-form-item label="状态">
          <el-select style="width: 90px" size="small" v-model="advancedForm.pass" clearable>
            <el-option v-for="item in options2" :key="item.value" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="申请时间">
          <el-date-picker style="width: 130px" size="small" v-model="create_time1" type="date"
            placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="">
          <el-input size="small" placeholder="店铺名称" v-model.trim="advancedForm.shop_name" clearable></el-input>
        </el-form-item>
        <el-button @click="GET_AccountList" type="primary" size="small" style="align-self: baseline;">搜索
        </el-button>
        <div class="col"></div>
        <!--     <el-button @click="handleGoodsAudit(idsList, true)" size="small" type="primary">批量审核</el-button> -->
        <!-- <el-button
          @click="handleBatchDelete()"
          size="small"
          type="primary"
        >批量删除
        </el-button> -->
        <el-button @click="handleExport" type="primary" size="small" style="align-self: baseline;">导出数据
        </el-button>
      </template>

      <template slot="table-columns">
        <el-table-column type="selection" width="40"></el-table-column>
        <el-table-column prop="shop_name" label="店铺名称" show-overflow-tooltip />
        <el-table-column prop="balance_account" label="提现金额">
          <template slot-scope="scope">
            {{ scope.row.balance_account | unitPrice("￥") }}
          </template>
        </el-table-column>
        <el-table-column prop="out_way_cash_money" label="申请内容" show-overflow-tooltip
          :formatter="out_way_cash_money"></el-table-column>

        <el-table-column label="申请时间" width="150">
          <template slot-scope="scope">
            {{ scope.row.create_time | unixToDate("yyyy-MM-dd hh:mm:ss") }}
          </template>
        </el-table-column>
        <!--类型-->
        <el-table-column prop="out_way_name" label="类型" :formatter="out_way"></el-table-column>
        <el-table-column prop="pass" label="状态" width="100">
          <template slot-scope="scope">
            {{ scope.row.pass | passFilter(that) }}
          </template>
        </el-table-column>
        <!--操作-->
        <el-table-column label="操作" width="210">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" v-if="scope.row.pass === 0" @click="handleAudit(scope.row)">审核
            </el-button>
            <el-button size="mini" type="primary" @click="getDetail(scope.row)">查看
            </el-button>
            <!-- <el-button size="mini" type="danger" @click="handleDelete([scope.row.id])">删除
            </el-button> -->
          </template>
        </el-table-column>
      </template>
      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no" :page-sizes="MixinPageSizes" :page-size="tableData.page_size"
        :layout="MixinTableLayout" :total="tableData.data_total" background></el-pagination>
    </en-table-layout>
    <!--    审核-->
    <x-dialog :proxy="withDrawalAuditProxy">
      <el-form :model="goodsAuditForm" :rules="goodsAuditRules" ref="goodsAuditForm" label-width="100px"
        style="margin-bottom: 20px;">
        <!--是否通过=-->
        <el-form-item label="申请供应商" prop="shop_name">
          <span>{{ goodsAuditForm_info.shop_name }}</span>
        </el-form-item>
        <el-form-item label="提现金额" prop="balance_account">
          <span>{{ goodsAuditForm_info.balance_account | unitPrice("￥") }}</span>
        </el-form-item>
        <el-form-item label="提现金额" prop="cash_arrive_money">
          <span>{{ goodsAuditForm_info.cash_money | unitPrice("￥") }}(手续费在提现金额中扣除)</span>
        </el-form-item>
        <el-form-item label="手续费" prop="fee_money">
          <span>
            {{ goodsAuditForm_info.fee_money | unitPrice("￥") }}
          </span>
        </el-form-item>
        <el-form-item label="是否通过" prop="pass">
          <el-radio-group v-model="goodsAuditForm.pass">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="2">不通过</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="转账截图" v-if="goodsAuditForm.pass === 1">
          <el-upload :action="`${MixinUploadApi}/?scene=goods`" :multiple="true" list-type="picture-card" :limit="3"
            :on-exceed="beforeExceed" :before-upload="beforeUpload" :on-remove="beforeRemove"
            :on-success="beforeSuccess">
            <i class="el-icon-plus"></i>
            <div slot="tip" class="el-upload__tip">最多允许上传3张截图，建议格式jpg、png，每张图片大小不超10M。</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="备注信息" prop="comment">
          <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="请输入审核备注(120字以内)"
            :maxlength="120" v-model="goodsAuditForm.comment"></el-input>
        </el-form-item>
      </el-form>
    </x-dialog>

    <!-- 查看 -->
    <x-dialog :proxy="showWithDrawalInfoProxy">
      <el-form :model="goodsAuditForm" :rules="goodsAuditRules" ref="goodsAuditForm" label-width="120px">
        <el-form-item label="申请企业" prop="shop_name">
          <span>{{ goodsAuditForm.shop_name }}</span>
        </el-form-item>
        <el-form-item label="提现金额" prop="balance_account">
          <span>{{ goodsAuditForm.balance_account | unitPrice("￥") }}</span>
        </el-form-item>
        <el-form-item label="提现/充值金额" prop="cash_money">
          <span>{{ goodsAuditForm.cash_money | unitPrice("￥") }}(手续费在提现金额中扣除)</span>
        </el-form-item>
        <el-form-item label="手续费" prop="fee_money">
          <span>{{ goodsAuditForm.fee_money }}</span>
        </el-form-item>
        <el-form-item label="审核状态" prop="pass">
          <span>{{ goodsAuditForm.pass | passFilter(that) }}</span>
        </el-form-item>
        <el-form-item label="备注" prop="comment">
          <span>{{ goodsAuditForm.comment || "无" }}</span>
        </el-form-item>
        <el-form-item label="转账截图" v-if="goodsAuditForm.auth_imgs && goodsAuditForm.pass === 1">
          <div style="display: flex;">
            <img style="width:100px;height:100px;margin: 0 5px 20px;"
              v-for="(item, index) in goodsAuditForm.auth_imgs_list" @click="handleImgLook(item)" :key="index"
              :src="item" alt class="pass_img" />
          </div>
        </el-form-item>
      </el-form>
    </x-dialog>

    <!-- 查看大图 -->
    <el-dialog :visible.sync="centerDialogVisible" modal close-on-click-modal custom-class="dialog">
      <el-carousel :autoplay="false" arrow="never">
        <el-carousel-item v-for="item in centerDialogList" :key="item">
          <img :src="item" style="width: 100%; height: 100%" alt="" />
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </div>
</template>

<script>
import * as API_account from "@/api/account";
import { Foundation } from "@/../ui-utils";
import EnTableLayout from "../../../../ui-components/TableLayout/src/main";
import xDialog from '@/components/x-dialog/x-dialog'
import { $xDialog } from "@/components/x-dialog/dialog.proxy";

export default {
  name: "goodsAudit",
  components: { EnTableLayout, xDialog },
  data () {
    return {
      withDrawalAuditProxy: $xDialog.create({
        title: '审核',
        wrapperClass: 'withdrawal-audit',
        width: '60vw',
        beforeCancel: () => this.withDrawalAuditProxy.dismiss(),
        beforeConfirm: () => this.submitGoodsAuditForm()
      }),
      showWithDrawalInfoProxy: $xDialog.create({
        title: '查看',
        wrapperClass: 'show-withdrawal-info',
        width: '50vw',
        disableCancel: true,
        disableConfirm: true
      }),
      imgList: [],
      that: this,
      options1: [
        {
          name: "全部",
          value: "-1",
        },
        {
          name: "提现",
          value: "1",
        },
        {
          name: "线下充值",
          value: "0",
        },
      ],
      options2: [
        {
          name: "全部",
          value: "-1",
        },
        {
          name: "待审核",
          value: "0",
        },
        {
          name: "已通过",
          value: "1",
        },
        {
          name: "已拒绝",
          value: "2",
        },
      ],
      idsList: [],
      // 列表loading状态
      loading: false,
      // 列表参数
      params: {
        page_no: 1,
        page_size: 20,
      },
      // 商品数据
      tableData: {},
      // 审核商品 表单
      goodsAuditForm: {
        comment: "",
        pass: 1,
      },
      goodsAuditForm_info: {},
      // 审核商品 表单校验
      goodsAuditRules: {
        comment: [
          { required: false, message: "请输入审核备注！", trigger: "blur" },
        ],
      },
      // 高级搜索数据
      advancedForm: {
        account_type: "2", //固定为 2：供应商 3自有供应商
        query_owned_type: 1, // 否查询自有供应商提现列表 0否 1是"
        // 类型
        out_way: 1,
        // 状态
        pass: "-1",
        // 店铺名称
        shop_name: "",
        // 审核时间
        create_time: "",
      },
      create_time1: "",
      tableDatapass: [],
      options: [
        {
          value: 0,
          label: "待审核",
        },
        {
          value: 1,
          label: "已通过",
        },
        {
          value: 2,
          label: "已拒绝",
        },
      ],
      centerDialogVisible: false, // 显示查看器
      centerDialogList: [],
    };
  },
  mounted () {
    this.GET_AccountList();
  },
  activated () {
    this.GET_AccountList();
  },
  filters: {
    passFilter (val, that) {
      return that.options.find(function (item) {
        return item.value === val;
      }).label;
    },
  },
  watch: {
    "goodsAuditForm.pass": function (newVal) {
      this.goodsAuditRules.comment[0].required = newVal === 4;
    },
  },
  methods: {
    handleImgLook (url) {
      console.log(url);
      this.centerDialogList = [url];
      // this.showViewer = true;
      this.centerDialogVisible = true;
    },
    /** 申请内容格式化 scope.row.out_way+scope.row.cash_money*/
    out_way_cash_money (row, column, cellValue) {
      const { out_way, cash_money } = row;
      if (out_way === 0) {
        return (row.out_way_cash_money = "线下充值" + cash_money + "元");
      } else if (out_way === 1) {
        return (row.out_way_cash_money = "转至会员账户" + cash_money + "元");
      } else if (out_way === 2) {
        return (row.out_way_cash_money = "转至支付宝" + cash_money + "元");
      } else if (out_way === 3) {
        return (row.out_way_cash_money = "转至银行卡" + cash_money + "元");
      }
    },
    /** 类型格式化 scope.row.out_way+scope.row.cash_money*/
    out_way (row, column, cellValue) {
      const { out_way } = row;
      if (out_way === 0) {
        return (row.out_way_name = "线下充值");
      } else if (out_way === 1) {
        return (row.out_way_name = "转至会员账户");
      } else if (out_way === 2) {
        return (row.out_way_name = "转至支付宝");
      } else if (out_way === 3) {
        return (row.out_way_name = "转至银行卡");
      }
    },
    // 获取财务审核列表
    GET_AccountList () {
      this.loading = true;
      this.advancedForm.create_time = this.create_time1
        ? new Date(this.create_time1).getTime() / 1000
        : "";
      this.advancedForm = {
        ...this.advancedForm,
        ...this.params,
      };
      API_account.getCashOutPage(this.advancedForm)
        .then((res) => {
          this.tableData = res;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    //导出订单
    handleExport () {
      const { advancedForm: params } = this;
      delete params.page_no;
      delete params.page_size;
      API_account.exportCashOutPage(params).then((response) => {
        const json = {
          sheet_name: "账户变动列表",
          sheet_values: response.map((item) => ({
            店铺名称: item.shop_name,
            账户余额: item.balance_account,
            申请内容:
              item.out_way == 0
                ? "线下充值"
                : item.out_way == 1
                  ? "转至会员账户"
                  : item.out_way == 2
                    ? "转至支付宝"
                    : "转至银行卡" + item.cash_money,
            申请时间: Foundation.unixToDate(item.create_time),
            类型:
              item.out_way == 0
                ? "线下充值"
                : item.out_way == 1
                  ? "转至会员账户"
                  : item.out_way == 2
                    ? "转至支付宝"
                    : "转至银行卡",
            状态:
              item.pass == 0
                ? "待审核"
                : item.pass == 1
                  ? "审核通过"
                  : "已拒绝",
          })),
        };
        this.MixinExportJosnToExcel(json, "账户变动列表");
      });
    },
    /** 删除 */
    handleDelete (id) {
      this.$confirm("确定要删除吗？", "提示", { type: "warning" })
        .then(() => {
          API_account.deleteCashOutPage({ ids: id }).then((res) => {
            this.$message.success("删除成功");
            this.GET_AccountList();
          });
        })
        .catch(() => {
        });
    },
    /** 批量删除 */
    handleBatchDelete () {
      if (this.idsList.length) {
        this.$confirm("确定要删除吗？", "提示", { type: "warning" })
          .then(() => {
            API_account.deleteCashOutPage({ ids: this.idsList }).then((res) => {
              this.$message.success("删除成功");
              this.GET_AccountList();
            });
          })
          .catch(() => {
          });
      } else {
        this.$message.error("请选择要删除的数据");
      }
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_AccountList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_AccountList();
    },

    /** 搜索事件触发 */
    searchEvent (data) {
      this.params = {
        ...this.params,
        keyword: data,
      };
      Object.keys(this.advancedForm).forEach((key) => delete this.params[key]);
      this.GET_AccountList();
    },

    /** 高级搜索事件触发 */
    advancedSearchEvent () {
      this.params = {
        ...this.params,
        ...this.advancedForm,
      };
      delete this.params.keyword;
      this.GET_AccountList();
    },
    /** 文件超出数量限制*/
    beforeExceed (files, fileList) {
      if (Object.keys(files).length > 3 || fileList.length === 3) {
        this.$message.error('当前转账截图最多只允许上传3张。')
        return false;
      }
    },

    // 文件上传之前校验
    beforeUpload (file) {
      if (file.name.split('.')[1] !== 'jpg' && file.name.split('.')[1] !== 'png') {
        this.$message.error('请上传jpg、png格式的图片')
        return false;
      }
      if ((file.size / (1024 * 1024)) > 10) {
        this.$message.error('请上传的图片大小不超过10M!')
        return false;
      }
    },

    // 文件上传成功
    beforeSuccess (response, file, fileList) {
      this.imgList = fileList
      if (this.imgList.length === 3) {
        document.querySelectorAll('.el-upload--picture-card')[0].style.display = 'none'
      }
    },

    // 文件移除
    beforeRemove (file, fileList) {
      this.imgList = fileList
      if (this.imgList.length !== 3) {
        document.querySelectorAll('.el-upload--picture-card')[0].style.display = 'inline-block'
      }
    },

    /** 审核 */
    handleAudit (row) {
      let ids = [];
      ids.push(row.id);
      this.idsList = ids;
      API_account.getCashOut(row.id).then((response) => {
        response.balance_account = row.balance_account;
        this.goodsAuditForm_info = response;
      });
      this.goodsAuditForm = {
        ids: ids,
        comment: "",
        pass: 2,
      };
      this.goodsAuditForm_info = {
        ...this.goodsAuditForm_info,
        ...row,
      };
      this.withDrawalAuditProxy.display()
    },
    /** 查看 */
    getDetail (row) {
      API_account.getCashOut(row.id).then((response) => {
        response.balance_account = row.balance_account;
        this.goodsAuditForm = response;
      });
      this.goodsAuditForm = {
        ...this.goodsAuditForm,
        ...row,
      };
      this.showWithDrawalInfoProxy.display()
    },

    /** 审核 表单提交 */
    submitGoodsAuditForm () {
      this.$refs["goodsAuditForm"].validate((valid) => {
        if (valid) {
          this.goodsAuditForm['authImgs'] = this.imgList.map(item => { return item.response.url }).join(',')
          API_account.getCashOutAudit(this.idsList, this.goodsAuditForm).then(
            (response) => {
              this.withDrawalAuditProxy.dismiss()
              this.$message.success("审核完成！");
              this.GET_AccountList();
            }
          );
        } else {
          this.$message.error("表单填写有误，请核对！");
          return false;
        }
      });
    },
    handleSelectionChange (val) {
      let ids = [];
      val.forEach((item) => {
        ids.push(item.id);
      });
      this.idsList = ids;
    },
  },
};
</script>

<style lang="scss" scoped>
.pass_img {
  width: 240px;
  height: 240px;
  margin-right: 8px;
}

.el-form-item {
  margin-bottom: 5px;
}

/deep/ .withdrawal-audit {
  .el-dialog__body {
    overflow-y: scroll;
    max-height: 450px;
    padding-bottom: 20px;
  }
}
</style>
